import request from '@/utils/request'


// 查询用户观看视频奖励列表
export function listLookReward(query) {
  return request({
    url: '/user/user-look-rewards/list',
    method: 'get',
    params: query
  })
}

// 查询用户观看视频奖励分页
export function pageLookReward(query) {
  return request({
    url: '/user/user-look-rewards/page',
    method: 'get',
    params: query
  })
}

export function changeIsOpen(data) {
  return request({
    url: '/user/user-look-rewards/changeIsOpen',
    method: 'post',
    data: data
  })
}

// 查询用户观看视频奖励详细
export function getLookReward(data) {
  return request({
    url: '/user/user-look-rewards/detail',
    method: 'get',
    params: data
  })
}

// 新增用户观看视频奖励
export function addLookReward(data) {
  return request({
    url: '/user/user-look-rewards/add',
    method: 'post',
    data: data
  })
}

// 修改用户观看视频奖励
export function updateLookReward(data) {
  return request({
    url: '/user/user-look-rewards/edit',
    method: 'post',
    data: data
  })
}

// 删除用户观看视频奖励
export function delLookReward(data) {
  return request({
    url: '/user/user-look-rewards/delete',
    method: 'post',
    data: data
  })
}
